import React from "react"
import { StyledHeader } from "./Header.styled"

interface SectionHeaderProps {
  title: string
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title }) => {
  return <StyledHeader>{title}</StyledHeader>
}

export default SectionHeader
