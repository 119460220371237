import styled from "styled-components"

export const StyledStars = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.marginXL} auto;
  img {
    width: 250px;
  }
  span {
    font-size: ${({ theme }) => theme.fontL};
    font-weight: normal;
    color: ${({ theme }) => theme.primary};
    margin: 5px;
  }
  p {
    font-style: italic;
    margin: ${({ theme }) => theme.marginS};
  }
`
