import React, { ReactNode } from "react"
import { StyledSectionGrid } from "./Section.styled"
import { SectionHeader, SectionText, SectionButton, SectionImg } from ".."
import Slider from "../../slider"

interface SectionProps {
  title: string
  text: string
  richText?: string
  darkBackground: boolean
  buttonText: string
  buttonUrl: string
  imgUrl: string
  sliderCategory?: string
  id?: string
  index?: number
  children?: ReactNode | ReactNode[]
}

const Section: React.FC<SectionProps> = ({
  title,
  text,
  richText,
  darkBackground,
  buttonText,
  buttonUrl,
  imgUrl,
  children,
  sliderCategory,
  id,
  index,
}) => {
  const leftTextSiteToDisplay = index % 2 === 0
  return (
    <StyledSectionGrid
      darkBackground={darkBackground}
      leftTextSiteToDisplay={leftTextSiteToDisplay}
    >
      <div className="gridOne">
        {imgUrl && <SectionImg imgUrl={imgUrl} />}
        {sliderCategory && (
          <Slider
            sliderCategory={sliderCategory.toLowerCase()}
            id={id}
            key={id}
          />
        )}
        {index && <div className="childrenGridFull">{children}</div>}
      </div>
      <div className="gridTwo">
        <SectionHeader title={title} />
        <SectionText text={text} richText={richText} />
        <div className={index && "childrenGridMobile"}>{children}</div>
        {buttonText && (
          <SectionButton buttonText={buttonText} buttonUrl={buttonUrl} />
        )}
      </div>
    </StyledSectionGrid>
  )
}

export default Section
