import styled from "styled-components"

export const StyledSlider = styled.div`
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;

  position: relative;
  transition: 2s all;
  img {
    max-height: 67vw !important;
  }
  .fullscreen img {
    max-height: 100vh !important;
  }
  @media (min-width: 800px) {
    max-width: 43vw;
    position: static;
    margin-left: 0;
    img {
      max-height: 40vw !important;
    }
    svg {
      max-width: 20px;
    }
  }
`
