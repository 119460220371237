import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StyledSlider } from "./Slider.styled"
import { QueryImgArray } from "../../types/interface"

import ImageGallery from "react-image-gallery"

interface SliderProps {
  readonly sliderCategory: string
  readonly id: string
}

const Slider: React.FC<SliderProps> = ({ sliderCategory, id }) => {
  const data: QueryImgArray = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            thumbnail: childImageSharp {
              fluid(maxWidth: 92) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const createImagesArray = () => {
    const imagesArray = data.images.edges
      .filter(item => item.node.relativePath.includes(sliderCategory))
      .map(image => ({
        original: image.node.childImageSharp.fluid.src,
        thumbnail: image.node.thumbnail.fluid.src,
        srcSet: image.node.childImageSharp.fluid.srcSet,
        name: image.node.name,
        imageSet: [
          {
            srcSet: image.node.childImageSharp.fluid.srcSetWebp,
            media: "(max-width: 1200px)",
            type: "image/webp",
          },
          {
            srcSet: image.node.childImageSharp.fluid.srcSet,
            media: "(min-width: 1200px)",
            type: "image/jpeg",
          },
        ],
      }))
    const returnArray = imagesArray
      .sort(function (a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
      .sort(function (a, b) {
        return a.name.length - b.name.length
      })

    return returnArray
  }
  return (
    <StyledSlider key={`slider-${id}`}>
      <ImageGallery
        lazyLoad
        items={createImagesArray()}
        useBrowserFullscreen={false}
      />
    </StyledSlider>
  )
}

export default Slider
