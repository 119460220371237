import styled from "styled-components"

export const StyledImg = styled.div`
  left: -30px;
  position: relative;
  z-index: 1;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  width: calc(100% + 40px);
  margin-bottom: ${({ theme }) => theme.marginXXL};

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 10px;
    right: -10px;
    border: 1px solid ${({ theme }) => theme.primary};
    z-index: -1;
  }
  @media (min-width: 1000px) {
    left: 0px;
    width: 100%;
  }
`

export const StyledBorder = styled.div``
