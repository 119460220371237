import styled from "styled-components"

export const StyledSection = styled.section`
  padding: ${({ theme }) => theme.marginXL};
  padding-top: ${({ theme }) => theme.marginXXL};
  background-color: ${({ theme, darkBackground }) =>
    darkBackground ? theme.secondary : theme.lighter};
  color: ${({ theme, darkBackground }) =>
    darkBackground ? theme.lighter : theme.secondary};
  :first-child {
    padding-top: 100px;
  }
`

export const StyledSectionGrid = styled(StyledSection)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "gridOne" "gridTwo";
  .childrenGridFull {
    display: none;
  }
  .childrenGridMobile {
    display: inline;
  }

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ${({ leftTextSiteToDisplay }) =>
      leftTextSiteToDisplay ? `"gridTwo gridOne"` : `"gridOne gridTwo"`};

    grid-gap: 50px;
    .gridOne {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .gridTwo {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
    }
    .childrenGridMobile {
      display: none;
    }
    .childrenGridFull {
      display: flex;
    }
  }
`
