import styled from "styled-components"

export const StyledHeader = styled.h2`
  margin-top: 0;
  :before {
    content: "";
    position: relative;
    padding-left: 5px;
    width: 100%;
    border-left: 0.1rem solid ${({ theme }) => theme.primary};
  }
`
