import React from "react"
import { StyledStars } from "./Stars.styled"
import starsSVG from "../../../images/stars.svg"

const SectionStars: React.FC = () => (
  <StyledStars>
    <img src={starsSVG} alt="Rating stars" />
    <p>
      <span>9,3</span>/10
    </p>
    <p>według portalu Booking.com</p>
  </StyledStars>
)

export default SectionStars
