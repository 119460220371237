import React from "react"
import { StyledSection } from "../Section/Section.styled"
import { SectionHeader, SectionText } from ".."

interface OnePageSectionProps {
  title: string
  text: string
  textArray: {
    html: string
  }[]
  id: string
}

const OnePageSection: React.FC<OnePageSectionProps> = ({
  title,
  text,
  textArray,
  id,
}) => (
  <StyledSection>
    <SectionHeader title={title} />
    <SectionText text={text} />
    {textArray.map(
      ({ html }, index) =>
        text && <SectionText richText={html} key={`${id}-text-${index}`} />
    )}
  </StyledSection>
)

export default OnePageSection
