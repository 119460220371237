import React from "react"
import { StyledButton } from "./Button.styled"
import { Link } from "gatsby"

interface SectionButtonProps {
  buttonText: string
  buttonUrl: string
}

const SectionButton: React.FC<SectionButtonProps> = ({
  buttonText,
  buttonUrl,
}) => (
  <StyledButton>
    {buttonUrl.includes("http") ? (
      <a href={buttonUrl}>
        <button>{buttonText}</button>
      </a>
    ) : (
      <Link to={buttonUrl}>
        <button>{buttonText}</button>
      </Link>
    )}
  </StyledButton>
)

export default SectionButton
