import styled from "styled-components"

export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  button {
    border: 1px solid ${({ theme }) => theme.primary};
    text-transform: uppercase;
    background-color: transparent;
    color: ${({ theme }) => theme.primary};
    min-width: 8rem;
    min-height: 2.5rem;
    :hover {
      color: ${({ theme }) => theme.lighter};
      background-color: ${({ theme }) => theme.primary};
    }
  }
`
