import React from "react"
import { StyledText } from "./Text.styled"

interface SectionTextProps {
  text?: string
  richText?: string
}

const SectionText: React.FC<SectionTextProps> = ({ text, richText }) => {
  return richText ? (
    <StyledText dangerouslySetInnerHTML={{ __html: richText }}></StyledText>
  ) : (
    <StyledText>
      <p>{text}</p>
    </StyledText>
  )
}

export default SectionText
