import React from "react"
import { StyledImg, StyledBorder } from "./Img.styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { QueryImgArray } from "../../../types/interface"

interface SectionImgProps {
  imgUrl: string
}

const SectionImg: React.FC<SectionImgProps> = ({ imgUrl }) => {
  const data: QueryImgArray = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const image = data.images.edges.find(item =>
    item.node.relativePath.includes(imgUrl)
  )

  return (
    <StyledImg>
      <StyledBorder></StyledBorder>
      <Img fluid={image && image.node.childImageSharp.fluid} />
    </StyledImg>
  )
}

export default SectionImg
